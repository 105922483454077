<template>
  <div class="search_college">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="nav_">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能查询</el-breadcrumb-item>
        <el-breadcrumb-item>提前批</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="search">
        <el-input placeholder="请输入关键词" v-model="check.name" @keyup.enter.native="doSearch" class="input-with-select">
          <el-button slot="append" @click="doSearch">
            <img src="@/assets/img/home/search.png" alt="" />
          </el-button>
        </el-input>
      </div> -->
    </div>


    <div class="tabs">

      <!--            所属地区-->
      <el-row class="search_line">
        <el-col :span="5"><span class="title">艺术体育类></span></el-col>
        <el-col :span="19">
          <span v-for="(item, i) in art_type1" :key="i" :class="{ act: check.advance_type == item.id }"
            @click="chooseType(item.id, item.children)">{{ item.name }}</span>
        </el-col>
      </el-row>
      <!--            院校类型-->
      <el-row class="search_line">
        <el-col :span="5"><span class="title">军队公安司法类></span></el-col>
        <el-col :span="19">
          <span v-for="(item, i) in art_type2" :key="i" :class="{ act: check.advance_type == item.id }"
            @click="chooseType(item.id, item.children)">{{ item.name }}</span>
        </el-col>
      </el-row>

      <!-- 招生批次 -->

      <el-row class="search_line">
        <el-col :span="5">
          <span class="title">专项其他类></span>
        </el-col>
        <el-col :span="19">
          <span v-for="(item, i) in art_type3" :key="i" :class="{
          act: check.advance_type == item.id
        }" @click="chooseType(item.id, item.children)">{{ item.name }}
          </span>
        </el-col>
      </el-row>

      <el-row class="search_line">
        <el-col :span="5">
          <span class="title">高职高专提前批类></span>
        </el-col>
        <el-col :span="19">
          <span v-for="(item, i) in art_type4" :key="i" :class="{
          act: check.advance_type == item.id
        }" @click="chooseType(item.id, item.children)">{{ item.name }}
          </span>
        </el-col>
      </el-row>
      <el-row class="search_line">
        <el-col :span="5">
          <span class="title">所属批次线></span>
        </el-col>
        <el-col :span="19">
          <span v-for="(item, i) in art_batch" :key="i" style="padding:0">
            <span v-if="batch_list.indexOf(Number(item.id)) > -1" @click="chooseBatch(item.id)">{{ item.name }}</span>
            <span v-else style="color: rgb(153, 153, 153);">{{ item.name }}</span>
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionalSearch",
  props: ["condition"],
  data() {
    return {
      check: {
        name: "",
        advance_type: '1',
        advance_batch: '7'
      },
      batch_list: [
        7, 3
      ],
      art_type1: [
        {
          id: "1",
          name: "体育类",
          children: [7, 3]
        },
        {
          id: "2",
          name: "美术与设计类",
          children: [7, 3]
        },
        {
          id: "6",
          name: "舞蹈类",
          children: [7, 3]
        },

        {
          id: "8",
          name: "播音与主持类",
          children: [7, 3]
        },
        {
          id: "9",
          name: "表演类",
          children: [7, 3]
        },
        {
          id: "11",
          name: "书法类",
          children: [7, 3]
        },
        {
          id: "22",
          name: "省级联考",
          children: [7]
        },
      ],
      art_type2: [
        {
          id: "13",
          name: "军队院校招飞",
          children: [7]
        },
        {
          id: "14",
          name: "军队院校",
          children: [1]
        },
        {
          id: "15",
          name: "公安院校",
          children: [1, 2, 3]
        },
        {
          id: "16",
          name: "司法院校",
          children: [1]
        },
      ],
      art_type3: [
        {
          id: "17",
          name: "地方公费师范生",
          children: [1, 2]
        },
        {
          id: "18",
          name: "国家专项",
          children: [7]
        },
        {
          id: "19",
          name: "地方专项",
          children: [7]
        },
        {
          id: "20",
          name: "其他类",
          children: [1, 2]
        },
      ],
      art_type4: [
        {
          id: "21",
          name: "高职高专提前批",
          children: [3]
        },
      ],
      art_batch: [
        {
          id: "4",
          name: "提前批",
        },
        {
          id: "7",
          name: "本科",
        },
        {
          id: "1",
          name: "本科一批",
        },
        {
          id: "2",
          name: "本科二批",
        },
        {
          id: "3",
          name: "高职高专批",
        },
        {
          id: "5",
          name: "本科A段",
        },
        {
          id: "6",
          name: "本科B段",
        },

      ],
    };
  },
  created() {
  },
  methods: {
    chooseType(value, children) {
      this.check.advance_type = value;
      this.batch_list = children
      this.check.advance_batch = children[0]
      this.$emit("onSearch", this.check);
    },
    chooseBatch(value) {
      this.check.advance_batch = value;
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
  },
};
</script>
<style lang="less">
.search_line {
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}

.search_line:last-child {
  border: 0;
}
</style>
<style lang="less" scoped>
.nav {
  margin: 20px auto;

  .nav_ {
    width: 75%;
    display: inline-block;
  }

  .search {
    width: 25%;
    display: inline-block;
  }
}

.search_college {
  .tabs {
    color: #666666;
    letter-spacing: 2px;
    border: 1px solid #dfdfdf;
    padding: 15px;

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 12px;
      line-height: 27px;
      margin-bottom: 3px;

      &:hover {
        background: #2b7bf3;
        color: white;
      }
    }
  }

  .act {
    background-color: #2b7bf3;
    color: #ffffff;
  }
}
</style>
