<template>
  <div class="body">
    <conditional-search @onSearch="Search"> </conditional-search>
    <div class="content">
      <div class="head">
        <table class="From">
          <thead>
            <tr class="head_title">
              <td width="150px">代码</td>
              <td width="500px">院校名称/专业名称</td>
              <td width="150px">计划</td>
              <!-- <td width="150px">收费标准</td> -->
              <td width="500px">备注</td>
            </tr>
            <tr v-if="titleList.title">
              <td colspan="6">{{ titleList.title }}</td>
            </tr>
            <tr v-if="titleList.content">
              <td colspan="6" style="font-size: 18;">{{ titleList.content }}</td>
            </tr>
          </thead>
          <tbody v-for="(item, key) in listData" :key="key">
            <tr v-if="item.is_school" class="school">
              <td>{{ item.school_id }}</td>
              <td>{{ item.school_name }}</td>
              <td>{{ item.school_plan }}</td>
              <td colspan="3" class="toSchool">
                <router-link :to="{ path: '/college/otherBatchScore', query: { cid: item.cid, sid: item.school_id } }">
                  <img src="@/assets/img/fenshuxian.png" style="width: 18px; height: 18px; vertical-align: text-bottom;"
                    alt="">
                  查看分数线
                </router-link>

              </td>
            </tr>
            <tr v-else>
              <td>{{ item.specialty_id | interpo }}</td>
              <td>{{ item.specialty_name }}</td>
              <td>{{ item.plan_num }}</td>
              <!-- <td>{{ item.tuition }}</td> -->
              <td> {{ item.tuition == null ? '' : item.tuition }} {{ item.remarks }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--        分页-->
    <div class="pagination">
      <el-pagination :hide-on-single-page="true" :page-size="page.pageSize" :current-page="page.pageNum" :pager-count="11"
        :total="page.total" class="page-box" layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import ConditionalSearch from './components/ConditionalSearch.vue';
import { mapState } from "vuex";
export default {
  components: { ConditionalSearch },
  name: '',
  data() {
    return {
      listData: [],
      page: {
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
      count: 1,
      check: {
        advance_type: 1,
        advance_batch: 7,
      },
      titleList: []
    };
  },

  computed: {
    ...mapState(["login", "userInfo"]),
  },
 
  created() {
    this.getData()
  },
  methods: {

    getData() {
      this.$fecth
        .post("enrollment_plan/getAdvanceBatch", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          is_wenli: this.userInfo.is_wenli,
          advance_type: this.check.advance_type,
          advance_batch: this.check.advance_batch,
        })
        .then(res => {
          this.listData = res.list
          this.titleList = res.title
          this.page.total = res.count
        });
    },
    Search(item) {
      this.check = item
      this.getData()
    },
    pageChange(page) {
      document.body.scrollTop = document.documentElement.scrollTop = 450
      this.page.pageNum = page
      this.getData()
    }
  },
};
</script>

<style scoped lang='less'>
.body {
  width: 1200px;
  margin: 20px auto;
}

.From {
  font-size: 16px;
  width: 1200px;

  border-collapse: collapse;
  margin-top: 40px;

  thead tr td {
    text-align: center;
    padding: 10px 30px;
    border: 1px solid #dfdfdf;
  }

  .head_title {
    background: #ebeef5;
    color: #519deb;
  }

  .test {
    word-wrap: break-word;
    word-break: break-all;
  }

  tbody td {
    padding: 10px 30px;
    text-align: center;
    border: 1px solid #dfdfdf;
  }

  .school {
    background: #ebeef5;
  }

  .toSchool a {
    color: #349dff;
  }
}

//分页
.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}
</style>
